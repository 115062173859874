import React from 'react'

class Question extends React.Component {
  state = {
    isOpen: false
  }

  toggle = () =>
    this.setState((state, props) => ({
      isOpen: !state.isOpen
    }))

  render() {
    const { question, answer } = this.props
    const { isOpen } = this.state

    return (
      <div className="faq-question">
        <img
          onClick={this.toggle}
          className={`question-icon ${isOpen ? 'active' : ''}`}
          src="/images/faq/faq-arrow.svg"
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            className={`question ${isOpen ? 'active' : ''}`}
            onClick={this.toggle}
          >
            {question}
          </div>

          <div className={`collapse ${isOpen ? 'active' : ''}`}>
            <span
              className="answer"
              dangerouslySetInnerHTML={{ __html: answer }}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default Question
