import React, { Component } from 'react'
import FAQs from '../components/FAQs'
import Layout from '../layout'

import Header from '../components/Header'
import Footer from '../components/Footer'
import SEO from '../components/SEO'

class FaqsPage extends Component {
  render() {
    return (
      <Layout>
        <SEO title='Frequently Asked questions' />
        <Header />
        <div style={{ background: 'white' }}>
          <FAQs />
          <Footer />
        </div>
      </Layout>
    )
  }
}

export default FaqsPage
