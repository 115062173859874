import React from 'react'
import { Link } from 'gatsby'
import Question from './question'

const faqs = [
  {
    question: 'Who is funding the savings?',
    answer:
      'You fund your own savings by linking your checking account as a funding source to an existing savings account where the funds are moved at the end of each week.'
  },
  {
    question: 'Is this safe to use?',
    answer:
      'To authenticate accounts, we\'ve integrated with Plaid, a San Francisco-based financial technology ("fintech") company that builds connections with thousands of financial institutions. They\'re a trusted partner to companies ranging from popular fintech applications to Fortune 500 companies and major banks. These relationships require the company to have strong security controls in place, and its platform is regularly audited and tested to ensure its controls meet industry standards. For example, Plaid ensures sensitive data (both in- motion and at-rest) is encrypted, and regularly completes SOC-2 audits. When you enter your online banking credentials, Plaid establishes an encrypted connection with your bank. This flow was designed with security in mind, as an easy alternative to entering static account and routing numbers. Once this connection is established, our application receives a token. This token enables us to get the data we need to provide our service without direct access to your bank account or credentials.'
  },
  {
    question: 'What do I need to sign up?',
    answer:
      'You need to have an existing checking and savings account. In some situations, we may need you to provide identification in order to verify your identity. If you don’t already have a savings account, you can find some great low cost options in our marketplace.'
  },
  {
    question: 'What about my privacy?',
    answer:
      'SQRL takes your privacy seriously. Click here to find out more about our <a href="/privacy-policy/">Privacy Policy</a> and <a href="/terms-of-use/">Terms of Use</a>.'
  },
  {
    question: 'How do you transfer funds?',
    answer:
      'Dwolla is a powerful payments platform that securely connects bank or credit union accounts to enable the transfer of money for any individual or organization in the U.S. By leveraging Dwolla’s Access API, we are providing an integrated payments experience for seamless funds transfers for our customers, while reducing the headaches associated with fraud, exposure of sensitive financial data, and complications of using third-party processors.'
  },
  {
    question: 'Who is this service for?',
    answer:
      'SQRL is for everyone who is looking for a simple way to train their well-being holistically. We provide a complete snapshot of your progress and encourage you to grow your physical activity, financial resilience and emotional mindfulness in a non judgemental way.'
  },
  {
    question: 'How much does SQRL cost?',
    answer:
      'SQRL is free to try and when you are ready to start saving, a $4.99 one-time fee available as an in-app purchase allows you to link your bank accounts and make the savings real.'
  },
  {
    question: 'Can I close my account anytime?',
    answer:
      'Yes, you can delete your account at anytime from the account menu in the SQRL app. This step de-links any bank accounts associated, removed on-demand authorization for transfers and cancels all scheduled transfers (that are not already pending) associated with your account.'
  },
  {
    question: 'Can I withdraw funds anytime?',
    answer:
      'Your money stays in the financial institutions you bring, so withdrawing funds is only possible from your respective banking products.'
  }
]

export default () => {
  const renderQuestions = faqs.map((d, i) => (
    <Question
      key={`FAQ_${i.toString()}`}
      question={d.question}
      answer={d.answer}
    />
  ))

  return (
    <div className="faq-wrapper">
      <h1>FAQ</h1>
      <h2>You’ve got questions.</h2>
      <p>
        Don’t see your question here?&nbsp;
        <Link to="/contact-us/">Contact us</Link>
      </p>
      {renderQuestions}
    </div>
  )
}
